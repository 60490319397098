export const menuItems = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'About',
    path: '#about'
  },
  {
    title: 'Blogs',
    path: '/blogs/',
  },
  {
    title: 'Faq',
    path: '#faq'
  },
  {
    title: 'Discount',
    path: '/discount/',
  },
  {
    title: 'Contact Us',
    path: '#contact',
  },
];
