import React from 'react';
import LogoStyles from '../styles/LogoStyles';

import HeaderLogo from '../images/header-logo.webp';

const Logo = () => (
  <LogoStyles to="/">
    <img src={HeaderLogo} alt="logo" />
  </LogoStyles>
);

export default Logo;
