import React from 'react';

const Pagination = ({
	currentPage,
	itemsPerPage,
	totalItems,
	onPageChange,
}) => {
	const totalPages = Math.ceil(totalItems / itemsPerPage);
	// Create an array of page numbers to display
	const displayPages = [];
	const numPagesToShow = 2;
	let startPage = Math.max(1, currentPage - Math.floor(numPagesToShow / 2));
	let endPage = Math.min(totalPages, startPage + numPagesToShow - 1);

	if (totalPages > 2 && totalPages < numPagesToShow) {
		displayPages
			.push(...Array(totalPages).keys())
			.map((num) => num + 1);
	} else {
		if (startPage > 1) {
			displayPages.push(1);
			if (startPage > 2) {
				displayPages.push('...');
			}
		}

		for (let i = startPage; i <= endPage; i++) {
			displayPages.push(i);
		}

		if (endPage < totalPages) {
			if (endPage < totalPages - 1) {
				displayPages.push('...');
			}
			displayPages.push(totalPages);
		}
	}

	return (
		<div>
			{/* Render the pagination buttons */}
			{displayPages.map((page) => (
				<button
					key={page}
					onClick={() => onPageChange(page)}
					disabled={typeof page === 'string'}
					className={`w-12 h-12 font-bold border border-[#00000045] ${
						currentPage === page ? 'text-[#6CB132]' : 'text-gray-500 '
					}`}
				>
					{page}
				</button>
			))}
		</div>
	);
};

export default Pagination;
