import React, { useEffect, useState } from 'react';
import GlobalStyles from '../styles/GlobalStyles';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';

const Layout = ({ children }) => {
  let [blogPathName, setblogPathName] = useState(false)
  
  let pathSplit, blogPage;
  useEffect(() => {
    pathSplit = window.location.pathname.split('/');
    blogPage = pathSplit.slice(-2)[0] !== 'blogs';
    setblogPathName(window.location.pathname.includes('/blogs/') && blogPage)
  })

  const renderChild = blogPathName ? <div className='flex my-20 justify-between w-11/12 mx-auto gap-x-8'><Sidebar/>{children}</div> : children;
  
  return <React.Fragment>
    <GlobalStyles />
    <Header/>
    {renderChild}
    <Footer />
  </React.Fragment>
};

export default Layout;
