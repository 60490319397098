import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { SlArrowRightCircle, SlMenu } from "react-icons/sl";
import { menuItems } from "../constants/navData";
import Logo from "./Logo";

const NavBarSmall = ({ setIsNavOpen, isNavOpen, currentLocation }) => {
  return (
    <nav className={`md:hidden z-[1002] fixed top-0 right-0 w-8/12 bg-white h-screen flex items-center justify-center ease duration-300 transform ${isNavOpen ? 'translate-x-0' : 'translate-x-[100%]'}`}>
      <button
        className="absolute top-8 right-8"
        onClick={() => setIsNavOpen(!isNavOpen)}
        onKeyDown={() => setIsNavOpen(!isNavOpen)}
        aria-label="Action Button"
      >
        <SlArrowRightCircle className="text-6xl text-red-500"/>
      </button>
      <ul className="w-full">
        {menuItems.map((item) => (
          <li
            key={item.path}
            className={`group w-full`}
          >
            <Link onClick={() => setIsNavOpen(false)} to={item.path.includes("#") ? `/${item.path}` : item.path} className={`text-[#64718A] flex justify-center text-3xl font-medium tracking-wider py-2 my-2 group-hover:text-black before-link w-full ${item.path === currentLocation ? "bg-gray-200 text-black" : ""}`}>
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};
const NavBarLarge = ({ currentLocation }) => {
  return (
    <nav className="hidden md:block">
      <ul className="flex justify-center items-center">
        {menuItems.map((item) => (
          <li
            key={item.path}
            className={`group relative nav-link mx-8 ${item.path === currentLocation ? "active" : ""}`}
          >
            <Link
              to={item.path.includes("#") ? `/${item.path}` : item.path}
              className={`text-[#64718A] inline-block text-3xl font-medium tracking-wider py-2 group-hover:text-black before-link`}
            >
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

const Header = () => {
  let [currentLocation, setCurrentLocation] = useState("");

  useEffect(() => {
    setCurrentLocation(window.location.hash || window.location.pathname);
  });

  const [isNavOpen, setIsNavOpen] = useState(false);

  return (
    <div className="w-full">
      <div className="w-11/12 mx-auto">
        <div className="flex justify-between items-center">
          <div className="logo">
            <Logo />
          </div>
          <div>
            <div className="">
              <button
                onClick={() => setIsNavOpen(!isNavOpen)}
                onKeyDown={() => setIsNavOpen(!isNavOpen)}
                className="text-black text-4xl md:hidden"
                aria-label="Action Button"
              >
                <SlMenu />
              </button>
            </div>
            <NavBarLarge currentLocation={currentLocation} />
            <NavBarSmall
              isNavOpen={isNavOpen}
              setIsNavOpen={setIsNavOpen}
              currentLocation={currentLocation}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
