import React from "react";
import { Link } from "gatsby";

const SidebarCard = ({ item, active }) => {
  return (
    <Link
      className={`hidden md:flex justify-between border-b border-b-[#CBD5E1] ${active} my-4 p-2 h-60 hover:rounded-md hover:shadow-md transition-all duration-300 ease-in-out group ${active ? 'rounded-md shadow-lg border' : ''}`}
      to={"/blogs/" + item.slug}
    >
      <div className="w-5/12 p-2 flex items-center">
        <img
          className="object-cover rounded-md md:h-full"
          src={item.image}
          alt="blog featured"
        />
      </div>
      <div className="w-7/12 p-2 overflow-hidden">
        <h2 className={`text-2xl text-black font-[500] group-hover:text-[#6CB132] ${active ? 'text-[#6CB231]' : ''}`}>{item.title}</h2>
        <p className="text-lg font-[500] text-gray-500">{item.pretext}</p>
      </div>
    </Link>
  );
};

export default SidebarCard;
