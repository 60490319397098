import React from "react";
import { footerData } from "../constants/footerData";

import FooterLogo from "../images/header-logo.png";
import { Link } from "gatsby";
import DishImage from "../images/Newsletter.webp";

const Footer = () => {
  return (
    <footer className="bg-[#071731]">
      <div
        style={{
          backgroundImage: `url(${DishImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="h-[30rem] flex items-center justify-center"
      >
        <div className="rounded-lg md:w-3/4 my-0 mx-auto flex flex-col md:flex-row justify-between items-center bg-[#000000CC] py-8">
          <div className="w-full md:w-1/2 px-8">
            <h2 className="text-4xl font-[500] mb-4 text-center md:text-left">
              Foodaholix Newsletter
            </h2>
            <p className="text-2xl text-white text-center md:text-left">
              Sign Up to our Newsletter to get fun updates on our app and future
              releases
            </p>
          </div>
          <div className="w-full md:w-1/2 px-8 my-8">
            <form className="flex">
              <input
                className="w-2/3 border-0 outline-none rounded-tl-md rounded-bl-md text-gray-800 text-2xl px-4"
                id="subscribe"
              />
              <label hidden htmlFor="subscribe">
                Subscribe
              </label>
              <button className="cursor-pointer py-4 px-12 bg-[#F1A73C] transition-all ease-in-out duration-300 text-2xl rounded-tr-md rounded-br-md">
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="mx-auto w-11/12 flex flex-wrap justify-between mt-32">
        <div className="w-full md:w-[33%] lg:w-[14%] flex flex-col">
          <img className="h-32 w-32" src={FooterLogo} alt="footer logo" />
        </div>
        <div className="w-full md:w-[33%] lg:w-[14%] flex flex-col">
          <h3 className="text-3xl font-[500] text-white mb-6 mt-6">
            {footerData.columnTwo.title}
          </h3>
          <p className="text-2xl text-gray-500 pb-4">
            {footerData.columnTwo.address}
          </p>
          <p className="text-2xl text-gray-500 pb-4">
            {footerData.columnTwo.email}
          </p>
        </div>
        <div className="w-full md:w-[33%] lg:w-[14%] flex flex-col">
          <h3 className="text-3xl font-[500] text-white mb-6 mt-6">
            {footerData.columnThree.title}
          </h3>
          {footerData.columnThree.menuItems.map((item) => (
            <Link
              key={item.title}
              to={item.link.includes("#") ? `/${item.link}` : `${item.link}`}
              className="text-2xl text-gray-500 hover:text-white my-2 transition-all duration-300 ease-in-out"
            >
              {item.title}
            </Link>
          ))}
        </div>
        <div className="w-full md:w-[33%] lg:w-[14%] flex flex-col">
          <h3 className="text-3xl font-[500] text-white mb-6 mt-6">
            {footerData.columnFour.title}
          </h3>
          {footerData.columnFour.menuItems.map((item) => (
            <Link
              key={item.title}
              to={item.link}
              className="text-2xl text-gray-500 hover:text-white my-2 transition-all duration-300 ease-in-out"
            >
              {item.title}
            </Link>
          ))}
        </div>
        <div className="w-full md:w-[33%] lg:w-[14%] flex flex-col">
          <h3 className="text-3xl font-[500] text-white mb-6 mt-6">
            {footerData.columnFive.title}
          </h3>
          <div className="flex flex-wrap gap-2">
            {footerData.columnFive.icons.map((item) => (
              <a
                key={Math.random() * 1000000000}
                rel="noreferrer"
                href={item.link}
                aria-label={item.name}
                target="_blank"
                className="w-16 h-16 text-gray-500 border border-gray-500 rounded-full p-4 flex justify-center items-center mr-4 hover:text-white hover:border-white transition-all duration-300 ease-in-out"
              >
                {item.icon}
              </a>
            ))}
          </div>
        </div>
        <div className="w-full md:w-[33%] lg:w-[14%] flex flex-col">
          <h3 className="text-3xl font-[500] text-white mb-6 mt-6">
            {footerData.columnSix.title}
          </h3>
          <div className="flex flex-wrap gap-2">
            {footerData.columnSix.icons.map((item) => (
              <a
                key={Math.random() * 1000000000}
                rel="noreferrer"
                href={item.link}
                aria-label={item.name}
                target="_blank"
                className="w-16 h-16 text-gray-500 border border-gray-500 rounded-full p-4 flex justify-center items-center mr-4 hover:text-white hover:border-white transition-all duration-300 ease-in-out"
              >
                {item.icon}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="mt-20 text-gray-500 text-2xl text-center pb-16">
        &copy; Copyright @ 2022 Uideas Solutions Inc. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
